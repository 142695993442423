import { Texts } from '../data/texts'
import { Gallery } from '../shared/components/gallery/Gallery'
import { AboutMeHeader } from './AboutMe/AboutMeHeader'
import { Introduction } from './AboutMe/Introduction'
import { Qualifications } from './AboutMe/Qualifications'
import './AboutMePage.scss'

export const AboutMePage = () => {
  return (
    <div className="c-aboutme-page">
      <AboutMeHeader></AboutMeHeader>
      <Introduction></Introduction>
      <Gallery folder='massages' basicImageData={Texts.Massages.CompleteImages} width={640} ratio={[3, 2]} eager={false}></Gallery>
      <Qualifications></Qualifications>
    </div>
  )
}
