import { useLocation, useRoutes } from 'react-router-dom'
import './App.scss'
import { routes } from './pages/router'
import { Menu } from './shared/components/menu/Menu'
import { Footer } from './shared/components/footer/Footer'
import { useEffect } from 'react'

function ScrollToTop () {
  const { pathname } = useLocation()

  useEffect(() => {
    document.getElementById('c-app-scroll-container')?.scrollTo(0, 0)
  }, [pathname])

  return null
}

export const App = () => {
  const routesElement = useRoutes(routes)

  return (
    <div className="c-app">
      <Menu></Menu>
      <div className='c-app__content' id='c-app-scroll-container'>
        <div className="c-app__main-wrapper">
          <ScrollToTop></ScrollToTop>
          {routesElement}
        </div>
        <Footer></Footer>
      </div>
    </div>
  )
}
