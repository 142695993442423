import { BotchType, IllustrationType } from '@benjaminpetry/sisterhoodmassagen-design'

export enum Massage {
    Wellness = 'Wellness',
    Pregnancy = 'Pregnancy',
    Postnatal = 'Postnatal',
}

export const ListOfMassages = [Massage.Wellness, Massage.Pregnancy, Massage.Postnatal]

export interface MassageData {
    name: string
    illustration: IllustrationType
    shortDescription: string
    description: string
    effectDescription: Array<string>
    prices: Array<string>
    anchor: string
    additionalBotch: BotchType
}

export const MassageDatabase: Record<Massage, MassageData> = {
  [Massage.Wellness]: {
    name: 'Wellnessmassage',
    illustration: IllustrationType.Wellness,
    shortDescription: 'Ganz- oder Teilkörpermassage, von sanft und ruhig bis kräftig und dynamisch - wir finden gemeinsam heraus, was du gerade brauchst.',
    description: `Die Sisterhood-Wellnessmassage ist eine entspannende, lockernde Massage, die effektiv auf individuelle Beschwerden und Wünsche eingeht. 
Bei einer Tasse Tee oder einem Glas Wasser besprechen wir vorab, was du dir für deine Massage wünschst. Druck und Dynamik variiere ich von sanft bis tief und von fließend bis punktuell, je nach deiner Präferenz und der Intention der Massage. Verschiedene Massagetechniken - wie z.B. das passive Dehnen und Mobilisieren von Gelenken sowie Fußreflex- und Tiefengewebsmassage - kombiniere ich in einer Massage passend auf dich abgestimmt.
Vor und während der Massage werden Liege und Laken durch ein regulierbares Wärmeunterbett für dich wohlig temperiert. Massiert wirst du mit einem leicht erwärmten reinen hautverträglichen Öl. Meine Öle sind hochwertige naturreine Produkte und ich biete geruchsneutrale sowie aromatisierte Massageöle an. Nach der Massage biete ich dir Zeit zum Ankommen und Nachruhen.
Massagen fördern die Gesundheit ganzheitlich. Diese Wirkung ist mit der Wechselbeziehung zwischen Körper und Geist zu erklären. Negativer Stress - körperlicher und seelischer Natur - steht mit vielen Krankheitsbildern in kausaler Beziehung. Durch das gezielte Lockern von physischen Verspannungen und Verhärtungen sowie das Freisetzen von Hormonen, die das seelische Wohlbefinden steigern, sind Massagen ein wertvolles Mittel der Stressbewältigung und somit der holistischen Gesundheitsvorsorge. 
Ein verbesserter Lymphfluss ist auch eine wertvolle Wirkungsweise von Massagen. Sanfter Druck auf das Gewebe unterstützt den Abtransport von Lymphflüssigkeit, welches der Entgiftung des Körpers und der Stärkung des Immunsystems dient. 
Zudem fördern Massagen eine tiefere Wahrnehmung für den eigenen Körper. Dies gelingt, indem sie helfen, bewusst auf eigene Körperempfindungen zu achten. Diese verbesserte Wahrnehmung ist wertvoll, da sie ermöglicht, frühzeitig auf ungesunden Stress zu reagieren und fördert so langfristig das Wohlbefinden durch einen achtsamen Umgang mit sich selbst.`,
    effectDescription: [
      'Lockert Verspannungen und Verhärtungen',
      'Reduziert Stress und fördert das mentale Wohlbefinden',
      'Kann ausgleichend auf das Hormonsystem wirken',
      'Fördert den Lymphfluss und somit die Entgiftung',
      'Fördert die eigene Körperwahrnehmung'
    ],
    prices: ['1,- Euro pro Minute,', 'ab 30 Minuten', ''],
    anchor: 'wellness',
    additionalBotch: BotchType.BluePurple1
  },
  [Massage.Pregnancy]: {
    name: 'Schwangerschaftsmassage',
    illustration: IllustrationType.Pregnancy,
    shortDescription: 'Eine Ganzkörpermassage in bequemer Seitenlage, die gezielt auf die besonderen Bedürfnisse der schwangeren Frau eingeht.',
    description: `Die Sisterhood-Schwangerschaftsmassage ist eine wohltuende Ganzkörpermassage in bequemer Seitenlage. Bei dieser Massage gehe ich gezielt auf die besonderen Bedürfnisse in der Schwangerschaft ein und nutze geschulte Griffe, die gezielt entlasten und gleichzeitig die Sicherheit von dir und deinem Kind gewährleisten. Somit ist diese Massage vom ersten bis zum letzten Tag der Schwangerschaft ohne Bedenken möglich.
Auf dich warten vorgewärmte Laken, von denen du dich während der Massage stets geschützt und bedeckt fühlst. Massiert wirst du dann mit einem leicht vorgewärmten geruchlosen reinen Mandelöl, oder nach Wunsch auch mit einem leicht aromatisierten Massageöl deiner Wahl.
Während der Massage liegst du gestützt durch weiche Polster bequem auf der Seite und fühlst dich so getragen und leicht. Obwohl die Seitenlage selten in konventionellen Massagen eingesetzt wird, eignet sie sich hervorragend für die Massage belasteter Körperregionen; auch des Rückens. Die Massage umfasst den Schulter-Nackenbereich, den gesamten Rücken und die obere Gesäßmuskulatur. Zudem werden Brustkorb und Schultern sanft gedehnt, als auch die Arme und Hände sowie Beine und Füße in angepasster Intensität mobilisiert und massiert.
Die Schwangerschaft ist eine transformative Zeit und geht mit körperlichen und emotionalen Herausforderungen für die werdende Mama einher. 
Die zahlreichen Vorgänge im Körper inklusive Gewichtszunahme und veränderter Statik führen häufig zu Beschwerden, wie muskulären Verspannungen, schmerzenden Füßen und Gelenken, Wassereinlagerungen in den Extremitäten, sowie allgemeiner Müdigkeit und Abgeschlagenheit. Eine Schwangerschaftsmassage kann diesen Beschwerden entgegenwirken, indem verspannte Muskeln gelockert und die Durchblutung und der Lymphfluss gefördert werden.
Auch die mentale Belastung der Transformation zum Mama-Sein ist häufig nicht unerheblich und kann mit unerwarteten Ängsten und Gefühlen verbunden sein. Hier unterstützt eine Schwangerschaftsmassage indem sie Stress abbaut, Ängste mindert und die Wahrnehmung des eigenen sich verändernden Körpers und die Verbindung zum heranwachsenden Kind stärkt.
Die regelmäßige Massage in der Schwangerschaft kann somit ein wertvoller Bestandteil der Schwangerenvorsorge und eine hilfreiche Vorbereitung auf die bevorstehende Geburt sein.`,
    effectDescription: [
      'Lindert muskuläre Verspannungen und Gelenkbeschwerden',
      'Verbessert Lymphfluss und reduziert Wassereinlagerungen',
      'Fördert die Durchblutung',
      'Pflegt die Haut und unterstützt die Elastizität',
      'Dient dem Stressabbau',
      'Verbessert die Schlafqualität',
      'Fördert die Körperwahrnehmung',
      'Unterstützt die Mutter-Kind-Bindung'
    ],
    prices: ['65,- Euro für 60 Minuten', '80,- Euro für 75 Minuten', '95,- Euro für 90 Minuten'],
    anchor: 'schwangerschaft',
    additionalBotch: BotchType.BlueOrange
  },
  [Massage.Postnatal]: {
    name: 'Postnatale Massage',
    illustration: IllustrationType.Postnatal,
    shortDescription: 'Eine Ganzkörpermassage in individueller Lagerung, die still- und tragebedingte Verspannungen lockert und zur Regeneration und Zentrierung beiträgt.',
    description: `Die Sisterhood-Postnatale-Massage ist eine wohltuende Ganzkörpermassage in individueller Lagerung, die still- und trage-bedingte Verspannungen lockert und zur körperlichen Regeneration und seelischen Zentrierung beiträgt. Aus eigener Erfahrung weiß ich, wie fordernd die ersten Monate mit einem Neugeborenen sind. Diese Massage bietet dir einen Moment der Selbstfürsorge und des Auftankens während dieser transformativen Zeit.
In den ersten 6-8 Wochen nach der Geburt deines Kindes sind Gewebe und Bänder weiterhin gelockert und dein Thromboserisiko erhöht. Insgesamt dauert die Rückbildung nach der Entbindung durchschnittlich 9 Monate. Bei stillenden Frauen dauert dieser Prozess allerdings länger, denn erst nach dem Stillen kommt es zu einer abschließenden Hormonumstellung. Daher sind vor allem im Wochenbett herkömmliche Massagen nicht zu empfehlen und mit dieser Massage biete ich dir eine sichere, wohltuende und effektive Alternative.
Es warten auf dich vorgewärmte Laken, von denen du dich während der Massage stets geschützt und bedeckt fühlst. Massiert wirst du dann mit einem leicht vorgewärmten geruchlosen, reinen Mandelöl, oder nach Wunsch auch mit einem aromatisierten Massageöl deiner Wahl.
Während der Massage wirst du nach deinen Bedürfnissen individuell auf der Seite, auf dem Bauch und/ oder auf dem Rücken gelagert und bequem gepolstert, sodass empfindliche Brüste, ein noch sensibler Bauch oder eine Kaiserschnittnarbe stets bequem und geschützt liegen.
Die Massage umfasst gezielt die im ersten Babyjahr stark beanspruchten Bereiche wie Schulter, Nacken, Rücken, obere Gesäß- und Beckenmuskulatur, Beine und Füße. Diese regenerative Massage umfasst - sofern möglich und gewünscht - auch eine Bauchmassage, die den Rückbildungsprozess unterstützt, die Verdauung fördert und den Lymphfluss in diesem Bereich anregt. Die Haut am Bauch wird vitalisiert und gepflegt, um sich wieder zu straffen. Sofern für dich stimmig, lade ich dich mit einer speziellen Wiegetechnik zum Fallenlassen und Getragenwerden ein. So wirst du für einen Moment von der Haltenden zur Gehaltenen.`,
    effectDescription: [
      'Reduziert Verspannungen und Verhärtungen',
      'Unterstützt die Regeneration nach der Schwangerschaft',
      'Verbessert Durchblutung und Lymphfluss',
      'Dient dem Stressabbau und der inneren Zentrierung',
      'Verbessert die Schlafqualität',
      'Fördert die Körperwahrnehmung nach der Geburt'
    ],
    prices: ['65,- Euro für 60 Minuten', '80,- Euro für 75 Minuten', '95,- Euro für 90 Minuten'],
    anchor: 'postnatal',
    additionalBotch: BotchType.BluePurple3
  }
}
