import { AssetFolder, Assets, Image } from '@benjaminpetry/sisterhoodmassagen-design'
import './Gallery.scss'

interface GalleryProps {
  folder: AssetFolder,
  basicImageData: Array<{name: string, alt: string}>,
  width?: number,
  ratio?: [number, number],
  eager: boolean
}

export const Gallery = ({ folder, basicImageData, width, ratio, eager }: GalleryProps) => {
  const images = basicImageData.map(data => {
    return Assets.folder(folder).imageSet(data.name, data.alt)
  })

  return (
    <section className="c-gallery">
        {images.map((img, index) => {
          return <Image key={index} {...img} className='c-gallery__image' width={width ?? 640} ratio={ratio ?? [3, 2]} eager={eager}></Image>
        })}
    </section>
  )
}
