import { Assets, Image } from '@benjaminpetry/sisterhoodmassagen-design'
import { Texts } from '../../data/texts'
import './AboutMeHeader.scss'

const HeaderData = Texts.AboutMe.Header

const headerImage = Assets.folder('aboutme').imageSet(HeaderData.Image, HeaderData.Alt)

export const AboutMeHeader = () => {
  return (
    <header className="c-about-me-header">
        <Image className='c-about-me-header__image' eager={true} {...headerImage} ratio={[16, 5]}></Image>
        <h2>{HeaderData.Title}</h2>
        <h3>{HeaderData.Subtitle}</h3>
    </header>
  )
}
