import { Contact } from './Contact/Contact'
import './ContactPage.scss'
import { Location } from './HomePage/Location'

export const ContactPage = () => {
  return (
    <main className="c-contact-page">
      <Contact></Contact>
      <Location></Location>
    </main>
  )
}
