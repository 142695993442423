import { IllustrationType } from '@benjaminpetry/sisterhoodmassagen-design'

export enum Benefit {
  Parking = 'Parking',
  Towel = 'Towel',
  Sustainability = 'Sustainability',
  Accessibility = 'Accessibility',
}

export const ListOfBenefits = [Benefit.Parking, Benefit.Towel, Benefit.Sustainability, Benefit.Accessibility]

export interface BenefitData {
  illustration: IllustrationType
  description: string
}

export const BenefitDatabase: Record<Benefit, BenefitData> = {
  [Benefit.Parking]: {
    illustration: IllustrationType.Car,
    description: 'Direkt vor dem Praxiseingang stehen dir Parkplätze zur Verfügung.'
  },
  [Benefit.Towel]: {
    illustration: IllustrationType.Towel,
    description: 'Du brauchst nichts mitbringen! Frische Laken liegen für dich bereit.'
  },
  [Benefit.Sustainability]: {
    illustration: IllustrationType.Flower,
    description: 'Ich verwende ausschließlich naturreine Öle und Essenzen.'
  },
  [Benefit.Accessibility]: {
    illustration: IllustrationType.Wheelchair,
    description: 'Ich begrüße dich in einer ebenerdigen barrierefreien Praxis.'
  }
}
