import { Button, Illustration } from '@benjaminpetry/sisterhoodmassagen-design'
import './MassageCard.scss'
import { MassageData } from '../../data/massages'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../router'

export interface MassageCardProps extends MassageData {
  className?: string
}

export const MassageCard = ({ name, illustration, className, shortDescription, prices, anchor }: MassageCardProps) => {
  const navigate = useNavigate()

  return (
    <div className={`c-massage-card c-massage-card--${anchor} ${className ?? ''}`}>
      <Illustration className='c-massage-card__illustration' illustration={illustration}></Illustration>
      <div className='c-massage-card__content'>
        <h3 className='c-massage-card__title'>{name}</h3>
        <p className='c-massage-card__description'>{shortDescription}</p>
        <div className='c-massage-card__prices'>
          <p className='c-massage-card__prices-title'>Preise:</p>
          <p className='c-massage-card__prices-text'>{prices.map((price) => (<span key={price}>{price || '\u00A0'}</span>))}</p>
        </div>
        <Button size='small' className='c-massage-card__more-button' classtype='secondary' onClick={() => navigate(AppRoutes.Massages(anchor))}>Mehr Informationen</Button>
      </div>
    </div>
  )
}
