import { Texts } from '../../data/texts'
import { Gallery } from '../../shared/components/gallery/Gallery'
import './MassageImageGallery.scss'

const { DetailImages } = Texts.Massages

export const MassageImageGallery = () => {
  return (
    <Gallery folder='massages' basicImageData={DetailImages} width={640} ratio={[3, 2]} eager={true}></Gallery>
  )
}
