import { Assets, Botch, BotchType, Image } from '@benjaminpetry/sisterhoodmassagen-design'
import { Texts } from '../../data/texts'
import './Welcome.scss'

const WelcomeImage = Assets.folder('photography').imageSet('Welcome-600px', '')

export const Welcome = () => {
  return (
    <section className="c-welcome">
      <h2 className='c-welcome__title'>{Texts.Welcome.Title}</h2>
      <div className='c-welcome__text-container'>
        <div className='c-welcome__text'>
          <Botch className='c-welcome__botch' botchType={BotchType.OrangeGreen}></Botch>
          <p>{Texts.Welcome.TextStart}</p>
          <ul>
            {Texts.Welcome.Questions.map((item, index) => {
              return <li key={index}>{item}</li>
            })}
          </ul>
          <p>{Texts.Welcome.TextEnd}</p>
        </div>
        <Image className='c-welcome__photography' eager={true} {...WelcomeImage} ratio={[3, 2]}></Image>
      </div>

    </section>
  )
}
