import { Botch, BotchType } from '@benjaminpetry/sisterhoodmassagen-design'
import { Texts } from '../../data/texts'
import './Introduction.scss'

const Data = Texts.AboutMe.Introduction

export const Introduction = () => {
  return (
    <section className="c-introduction">
      <h2 className='c-introduction__title'>{Data.Title1}<br />{Data.Title2}</h2>
      <div className='c-introduction__wrapper'>
        <div className='c-introduction__content'>
          <Botch className='c-introduction__botch c-introduction__botch-1' botchType={BotchType.BlueYellow}></Botch>
          <Botch className='c-introduction__botch c-introduction__botch-2' botchType={BotchType.OrangePink}></Botch>
          <Botch className='c-introduction__botch c-introduction__botch-3' botchType={BotchType.BluePurple3}></Botch>
          {Data.Content.split('\n').map((paragraph, index) => {
            return <p key={index} className='c-introduction__paragraph'>{paragraph}</p>
          })}
        </div>
      </div>
    </section>
  )
}
