import { Botch, BotchType } from '@benjaminpetry/sisterhoodmassagen-design'
import { Texts, Weekday, Weekdays } from '../../data/texts'
import { AppRoutes } from '../router'
import './OpeningHours.scss'

export const OpeningHours = () => {
  const renderDay = (day: Weekday) => {
    return (<div key={day} className='c-opening-hours__row'>
      <span className='c-opening-hours__day'>{day}</span>
      <span className='c-opening-hours__hours'>{Texts.OpeningHours.Hours[day]}</span>
      </div>)
  }

  return (
    <section className="c-opening-hours">
      <h2 className='c-opening-hours__title'>{Texts.OpeningHours.Title}</h2>
      <Botch className='c-opening-hours__botch' botchType={BotchType.OrangePink}></Botch>
      <div className='c-opening-hours__text-container'>
        {Weekdays.map(renderDay)}
      </div>
      <p className='c-opening-hours__hint'>{Texts.OpeningHours.HintStart}<a href={AppRoutes.Contact()}>{Texts.OpeningHours.HintLink}</a>{Texts.OpeningHours.HintEnd}</p>
    </section>
  )
}
