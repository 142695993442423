import { AppRoutes } from '../router'
import './AgbPage.scss'

export const AgbPage = () => {
  return (
    <main className="c-agb">
      <section className='c-agb__header'>
        <h2>Allgemeine Geschäftsbedingungen</h2>
      </section>
      <section>
        <h3>§1 Geltungsbereich</h3>
        <ol>
          <li>Diese AGB gelten für alle derzeitigen und zukünftigen Dienstleistungen, die ich, Isabelle Petry, im Rahmen meines Massageangebots durchführe. Mit der Terminbuchung, unabhängig vom Kommunikationsweg, akzeptiert die Kundin diese AGB in ihrer zum Zeitpunkt der Buchung gültigen Fassung.</li>
          <li>Zur besseren Lesbarkeit wird in diesen AGB die weibliche Form &apos;Kundin&apos; verwendet. Diese Bezeichnung umfasst jedoch alle Personen unabhängig von ihrem Geschlecht oder ihrer Geschlechtsidentität. Die AGB gelten somit für alle Kund*innen, unabhängig davon, ob sie sich als weiblich, männlich, nicht-binär oder einer anderen Geschlechtsidentität zugehörig fühlen.</li>
        </ol>
      </section>
      <section>
        <h3>§2 Leistungsangebot</h3>
        <ol>
          <li>Ich biete Massagen für Frauen als Gesundheitsvorsorge und Form des zwischenmenschlichen Kontakts an. Die Massagen dienen der Entspannung, Prävention und der Steigerung des allgemeinen Wohlbefindens. Sie ersetzen keine medizinischen Behandlungen und werden nicht zur Heilung von Krankheiten angeboten.</li>
          <li><strong>Praxis</strong>: Die Massagen werden in der Hebammenpraxis in Bargteheide, Heinrich-Hertz-Straße 23a, 22941 Bargteheide durchgeführt.</li>
          <li><strong>Hausbesuche</strong>: Auf Anfrage komme ich für Massagen ins Wochenbett auch zu dir nach Hause. Die Orte Bargteheide, Elmenhorst, Tremsbüttel, Hammoor, Delingsdorf, Jersbek und Timmerhorn sind im Rahmen meines Angebots ohne zusätzliche Fahrtkosten erreichbar. Für Anfahrten außerhalb dieser Gebiete wird eine Kilometerpauschale pro gefahrenem Kilometer zum Massagepreis hinzugerechnet.<br />&nbsp;<br />Bitte sorge dafür, dass genügend Platz vorhanden ist, eine angenehme Raumtemperatur herrscht und die Umgebung ruhig ist, um eine entspannte Behandlung zu gewährleisten. In Ausnahmefällen, wie z.B. im Wochenbett, können nach vorheriger Absprache auch andere Familienmitglieder, wie z.B. der Vater oder die Co-Mutter, massiert werden.</li>
          <li>Ich biete ausschließlich Wellnessmassagen an, die der Entspannung und dem Wohlbefinden dienen. Erotische Massagen oder entsprechende Anfragen werden grundsätzlich abgelehnt. Jede Aufforderung zu erotischen Dienstleistungen führt ohne Rückerstattung des Preises zum sofortigen Abbruch der Massage.</li>
        </ol>
      </section>
      <section>
        <h3>§3 Terminvereinbarung, Stornierung und Einverständniserklärung zur Massagebehandlung</h3>
        <ol>
          <li>Termine können online über Calendly, per Telefon, WhatsApp, Telegram oder E-Mail vereinbart werden.</li>
          <li>Eine kostenfreie Absage oder Verschiebung ist bis 24 Stunden vor dem Termin möglich. Dies kann über die oben genannten Kommunikationswege geschehen.</li>
          <li>Absagen oder Verschiebungen, die weniger als 24 Stunden vor dem Termin erfolgen, oder Nichterscheinen ohne Absage können mit einer Stornierungsgebühr von 50 % bis 100 % des vereinbarten Preises belegt werden, abhängig von der entstandenen Beeinträchtigung und dem entgangenen Umsatz.</li>
          <li>Bei verspätetem Erscheinen wird die Anwendungszeit entsprechend der verbleibenden Zeit angepasst, der volle Preis der ursprünglich gebuchten Massage bleibt jedoch bestehen, um die Zeitplanung für nachfolgende Termine einhalten zu können.</li>
          <li>Solltest du Krankheitssymptome oder Anzeichen einer möglichen Krankheit haben, bitte ich dich, deinen Termin so schnell wie möglich abzusagen. Da Massage eine körpernahe Tätigkeit ist, besteht ein erhöhtes Ansteckungsrisiko. Ich helfe dir gerne, einen zeitnahen Ersatztermin zu finden. Deine Gesundheit und Sicherheit, sowie die Sicherheit anderer Kundinnen, stehen an erster Stelle.</li>
          <li>Wenn ich aus unvorhersehbaren Gründen (z.B. Krankheit oder höhere Gewalt) den Termin absagen muss, wird ein Ersatztermin angeboten. Ein Anspruch auf Schadensersatz oder sonstige Entschädigung besteht nicht.</li>
          <li>Anwendungen können abgelehnt werden, wenn die Kundin unter Alkohol- oder Drogeneinfluss steht. Zudem wird empfohlen, die Massage nicht mit vollem Magen in Anspruch zu nehmen, um das Wohlbefinden währenddessen zu maximieren.</li>
        </ol>
        <p>Vor deiner ersten Massage bitte ich dich, die Einverständniserklärung auf meiner Webseite sorgfältig zu lesen und bei der Buchung über Calendly zu bestätigen. Bei Buchungen über andere Kommunikationswege (Telefon, WhatsApp, Telegram, E-Mail) gilt die Zustimmung zu den AGB und der Einverständniserklärung automatisch als erteilt.</p>
        <p>Es ist wichtig, dass du mich über bestehende gesundheitliche Zustände informierst, die die Massage beeinflussen könnten, wie Schwangerschaft, kürzlich durchgeführte Operationen, Krampfadern oder andere gesundheitliche Aspekte. Bei Unsicherheiten oder Fragen zur Massage in der Schwangerschaft kontaktiere mich gerne vorab und sprich mit deiner Hebamme oder deinem Arzt/Ärztin.</p>
      </section>
      <section>
        <h3>§4 Preise und Zahlungungsbedingungen</h3>
        <ol>
          <li>Es gelten die Preise, die zum Zeitpunkt der Buchung auf meiner Webseite veröffentlicht sind. Diese sind unter <a href={AppRoutes.Massages()}>Massagen</a> zu finden.</li>
          <li>Die Bezahlung ist vor Ort in bar, per PayPal oder mit einem Gutschein möglich. Sollte der Wert des Gutscheins oder der Restwert nicht ausreichen, um den gesamten Betrag zu decken, ist der verbleibende Restbetrag bar oder per PayPal zu begleichen. In Ausnahmefällen ist auch eine Überweisung möglich. Kartenzahlung ist nicht möglich.</li>
        </ol>
      </section>
      <section>
        <h3>§5 Gutscheine</h3>
        <ol>
          <li>Gutscheine sind bis einschließlich 31.12. des dritten Jahres nach Kauf gültig. Ein Gutschein, der z.B. am 24.09.2024 gekauft wurde, ist bis zum 31.12.2027 gültig.</li>
          <li>Die Haftung für den Verlust von Gutscheinen wird nicht übernommen, und ich bin nicht zum Ersatz verpflichtet.</li>
          <li>Auch die digitale Version des Gutscheins (z.B. PDF) gilt als gültiger Gutschein.</li>
          <li>Gutscheine sind übertragbar und können vor Ort eingelöst werden.</li>
          <li>Eine teilweise Einlösung eines Gutscheins ist möglich.</li>
          <li>Nach Ablauf der Gültigkeit verfällt der Gutschein, und es besteht kein Anspruch auf Einlösung oder Auszahlung.</li>
          <li>Eine Auszahlung des Gutscheinwertes ist generell ausgeschlossen, es sei denn, eine Einlösung der vereinbarten Dienstleistung ist aufgrund betrieblicher Gegebenheiten nicht mehr möglich.</li>
        </ol>
      </section>
      <section>
        <h3>§6 Entspannungsbonus</h3>
        <ol>
          <li>Der Entspannungsbonus ist ein Rabatt in Höhe eines festgelegten Betrages, der bei der nächsten Massage angewendet werden kann.</li>
          <li>Der Entspannungsbonus ist ein Jahr nach Erstellung gültig. Ein Entspannungsbonus, der z.B. am 24.09.2024 erstellt wurde, gilt bis zum 23.09.2025.</li>
          <li>Der Entspannungsbonus muss vollständig in einer Sitzung eingelöst werden und kann nicht aufgeteilt werden.</li>
          <li>Eine Barauszahlung ist ausgeschlossen.</li>
          <li>Nach Ablauf ist der Entspannungsbonus nicht mehr gültig und kann auch nicht ausgezahlt werden.</li>
        </ol>
      </section>
      <section>
        <h3>§7 Haftung</h3>
        <ol>
          <li>Es liegt in der Verantwortung der Kundin, mich über alle relevanten gesundheitlichen Probleme vorab zu informieren. Ich übernehme keine Haftung für gesundheitliche Schäden, die durch das Verschweigen relevanter gesundheitlicher Informationen entstehen. Ebenso wird keine Haftung übernommen, wenn Schäden auftreten, weil ein Ausschlussgrund der Kundin nicht bekannt war und für mich nicht erkennbar war.</li>
          <li>Die Haftung für Schäden beschränkt sich auf grobe Fahrlässigkeit oder vorsätzliches Fehlverhalten meinerseits.</li>
          <li>Zu den gesundheitlichen Risiken, bei denen eine Massage kontraindiziert sein kann, gehören insbesondere:
            <ul>
              <li>Hautinfektionen und offene Wunden</li>
              <li>Akute Entzündungen und Fieber</li>
              <li>Herz-Kreislauf-Erkrankungen</li>
              <li>Fortgeschrittene Osteoporose</li>
              <li>Epilepsie und andere neurologische Erkrankungen</li>
              <li>Hautkrebs und andere Krebsformen im fortgeschrittenen Stadium</li>
              <li>starke Durchblutungsstörungen</li>
            </ul>
          </li>
        </ol>
      </section>
      <section>
        <h3>§8 Datenschutz</h3>
        <ol>
          <li>Deine persönlichen Daten werden streng vertraulich behandelt und ausschließlich für die Bearbeitung deiner Anfrage sowie zur Durchführung der Massage genutzt.</li>
          <li>Bei der Buchung eines Termins über Calendly werden ebenfalls persönliche Daten wie Name, E-Mail-Adresse und Telefonnummer erfasst, um die Terminverwaltung und Kommunikation zu ermöglichen. Diese Daten werden von Calendly auf sicheren Servern gespeichert und gemäß den geltenden Datenschutzgesetzen verarbeitet. Calendly agiert als Auftragsverarbeiter und speichert die Daten im Rahmen der Terminverwaltung. Zudem verarbeitet Calendly die von dir angegebenen Informationen streng vertraulich und nur im Rahmen der Terminbuchung. Weitere Informationen über die Datenschutzrichtlinien von Calendly findest du in der <a href='https://calendly.com/legal/privacy-notice' rel='noreferrer' target='_blank'>Calendly-Datenschutzerklärung</a>.</li>
          <li>Durch das Anhaken der Checkbox bei der Buchung auf Calendly erklärst du dein vollständiges Einverständnis mit den oben genannten Bedingungen. Bei Buchungen per Telefon, WhatsApp, Telegram oder E-Mail gilt die Einwilligung, soweit zutreffend, durch die Inanspruchnahme der Dienstleistung als gegeben.</li>
        </ol>
      </section>
      <section>
        <h3>§9 Salvatorische Klausel</h3>
        <ol>
          <li>Sollte eine Bestimmung dieser AGB unwirksam sein, bleibt die Wirksamkeit der übrigen Bestimmungen unberührt. Die unwirksame Bestimmung wird durch eine solche ersetzt, die dem wirtschaftlichen Zweck der unwirksamen Bestimmung am nächsten kommt.</li>
        </ol>
      </section>
      <section>
        <h3>§10 Gerichtsstand</h3>
        <ol>
          <li>Es gilt das Recht der Bundesrepublik Deutschland. Gerichtsstand ist der Sitz von Isabelle Petry (Sisterhood Massagen).</li>
        </ol>
      </section>
    </main>
  )
}
