import { Flower, FlowerType, Illustration, IllustrationType } from '@benjaminpetry/sisterhoodmassagen-design'
import './Home.scss'
import { Texts } from '../../data/texts'

export const Home = () => {
  return (
    <section className="c-home">
        <Flower className='c-home__flower-left' eager={true} flowerType={FlowerType.Corner1}></Flower>
        <Flower className='c-home__flower-right' eager={true} flowerType={FlowerType.Corner4}></Flower>
        <div className='c-home__content'>
            <div className='c-home__title'>Massagen</div>
            <div className='c-home__subtitle'>für jede Frau</div>
        </div>
        <Illustration className='c-home__illustration' eager={true} illustration={IllustrationType.Sisterhood}></Illustration>
        <p className='c-home__quote'>
          &quot;{Texts.Quote[0]}<br />
          {Texts.Quote[1]}&quot;
        </p>
    </section>
  )
}
