import { Botch, CalendlyButton, Illustration, Link } from '@benjaminpetry/sisterhoodmassagen-design'
import { useNavigate } from 'react-router-dom'
import { MassageData } from '../../data/massages'
import { AppRoutes } from '../router'
import './MassageSection.scss'

export interface MassageSectionProps extends MassageData {
  className?: string
}

export const MassageSection = ({ name, illustration, className, description, effectDescription, prices, anchor, additionalBotch }: MassageSectionProps) => {
  const navigate = useNavigate()

  const scrollToTop = () => {
    navigate(AppRoutes.Massages('top'))
  }
  return (
    <div className={`c-massage-section c-massage-section--${anchor} ${className ?? ''}`}>
      <div className='c-massage-section__header'>
        <h2 id={anchor}>{name}</h2>
        <Link onClick={scrollToTop} className='c-massage-section__to-top c-desktop-only'>Nach oben</Link>
      </div>
      <div className='c-massage-section__content'>
        <div className="c-massage-section__teaser">
          <Illustration className='c-massage-card__illustration' illustration={illustration}></Illustration>
          <div className='c-massage-section__teaser-content'>
            <div className="c-massage-section__price">
              <h3>Preise</h3>
              <p>{prices.map((price) => (<span key={price}>{price}</span>))}</p>
            </div>
            <CalendlyButton size='small'></CalendlyButton>
          </div>
        </div>
        <div className='c-massage-section__description'>
          <div className='c-massage-section__description-container'>
            <h3>Beschreibung</h3>
            <div className='c-massage-section__description-text'>{description.split('\n').map((item, index) => <p key={index}>{item}</p>)}</div>
          </div>
          <div className='c-massage-section__effect-container'>
            <h3>Wirkungsweise</h3>
            <div className='c-massage-section__effect-text'>
              <ul>
                {effectDescription.map((effect, index) => <li key={index}>{effect}</li>)}
              </ul>
            </div>
          </div>
          <div className="c-massage-section__price-mobile">
            <h3>Preise</h3>
            <p>{prices.map((price) => (<span key={price}>{price}</span>))}</p>
          </div>
          <Botch botchType={additionalBotch}></Botch>
        </div>
        <CalendlyButton className='c-massage-section__calendly-button-mobile' size='small'></CalendlyButton>
      </div>
    </div>
  )
}
