import { Assets, Botch, BotchType, Button, Illustration, IllustrationType, openLink, SvgImage } from '@benjaminpetry/sisterhoodmassagen-design'
import { Texts } from '../../data/texts'
import './Contact.scss'

const Data = Texts.Contact

export const Contact = () => {
  return (
    <section className="c-contact">
      <h2 className='c-contact__title'>{Data.Title}</h2>
      <Botch className='c-contact__botch' botchType={BotchType.YellowPurple}></Botch>
      <div className='c-contact__wrapper'>
        <div className='c-contact__illustration-container'>
          <Illustration className='c-contact__illustration' illustration={IllustrationType.Sisterhood}></Illustration>
        </div>
        <div className='c-contact__content'>
          <div className='c-contact__box'>
            <h3 className='c-contact__title'>{Data.ContactTitle}</h3>
            <div className='c-contact__text'>
              <p>{Data.Name}</p>
              <p>Mobil: {Data.Mobile}</p>
              <p>E-Mail: <a href={`mailto:${Data.Email}`}>{Data.Email}</a></p>
            </div>
          </div>

          {/* <h3 className='c-contact__title'>{Data.ChannelsTitle}</h3> */}
          <div className='c-contact__channels'>
            {Data.Channels.map((channel) => (
              <div className='c-contact__channel' key={channel.name}>
                <h3 className='c-contact__channel-title'>{channel.name}</h3>
                <SvgImage className='c-contact__channel-image' src={Assets.folder('qrcodes').file(channel.qrcode)} alt='' ratio={[1, 1]}></SvgImage>
                <a className='c-contact__channel-link' href={channel.link} target='_blank' rel='noreferrer'>{channel.subtitle}</a>
                <p className='c-contact__channel-subtitle'>{channel.subtitle}</p>
                <Button className='c-contact__channel-button' classtype='secondary' size='small' onClick={() => openLink(channel.link)}>In {channel.name} öffnen</Button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
