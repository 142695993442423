import { Assets, Botch, BotchType, Image } from '@benjaminpetry/sisterhoodmassagen-design'
import { Texts } from '../../data/texts'
import './Promotion.scss'

const VoucherImage = Assets.folder('illustrations').imageSet(Texts.Promotion.Image, Texts.Promotion.Alt)

export const Promotion = () => {
  return (
    <section className="c-promotion">
      <div className='c-promotion__wrapper'>
        <div className='c-promotion__content'>
          <h3 className='c-promotion__title'>{Texts.Promotion.Title}</h3>
          <div className='c-promotion__text'>
            <Botch className='c-promotion__botch' botchType={BotchType.BluePurple3}></Botch>
            {Texts.Promotion.Text.map((item, index) => {
              return <p key={index} className={`c-promotion__paragraph--${item.highlight ? 'highlight' : 'normal'}`}>{item.text}</p>
            })}
          </div>
        </div>
        <div className='c-promotion__illustration-container'>
          <Image className='c-promotion__image' {...VoucherImage}></Image>
        </div>
        {/* <Photography basename='Massage-Back' alt='Zwei Hände die den Rücken massieren'></Photography> */}
      </div>
    </section>
  )
}
