import { Assets, Botch, BotchType, Image } from '@benjaminpetry/sisterhoodmassagen-design'
import { Texts } from '../../data/texts'
import './Qualifications.scss'

const Data = Texts.AboutMe.Qualifications
const introImage = Assets.folder('aboutme').imageSet(Data.Image, Data.Alt)

export const Qualifications = () => {
  return (
    <section className="c-qualifications">
      <h2 className='c-qualifications__title'>{Data.Title}</h2>
      <div className='c-qualifications__wrapper'>
        <Image className='c-qualifications__image' {...introImage} ratio={[600, 465]}></Image>
        <div className='c-qualifications__content'>
          <Botch className='c-qualifications__botch' botchType={BotchType.YellowPurple}></Botch>
          <div className='c-qualifications__list'>
            <h3>{Data.Education.Title}</h3>
            <ul>
              {Data.Education.Items.map((item, index) => {
                return <li key={index}>{item}</li>
              })}
            </ul>
          </div>
          <div className='c-qualifications__list'>
            <h3>{Data.AdvancedEducation.Title}</h3>
            <ul>
              {Data.AdvancedEducation.Items.map((item, index) => {
                return <li key={index}>{item}</li>
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}
