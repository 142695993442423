import { Separator } from '@benjaminpetry/sisterhoodmassagen-design'
import { AppRoutes } from '../../../pages/router'
import { MenuItem } from '../menu/MenuItem'
import './Footer.scss'
import { Texts } from '../../../data/texts'

export const Footer = () => {
  return (
    <footer className="c-footer">
      <div className='c-footer__disclaimer'>{Texts.Disclaimer}</div>
      <Separator sizeAppearance='small' className='c-mobile-only'></Separator>
      <div className='c-footer__links'>
        <MenuItem url={AppRoutes.Impressum()}>Impressum</MenuItem>
        <MenuItem url={AppRoutes.AGBs()}>AGBs</MenuItem>
        <MenuItem url={AppRoutes.PrivacyPolicy()}>Datenschutzerklärung</MenuItem>
        <MenuItem url={AppRoutes.Agreement()}>Einverständniserklärung</MenuItem>
      </div>
    </footer>
  )
}
